<template>
    <div class="news">
        <Header/>
        <div class="banner">
            <div class="content">
                <User/>
                <div class="main">
                    <div class="head">
                        <div class="title">
                            新闻资讯
                        </div>
                        <div class="bag">当前位置：
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                                <el-breadcrumb-item>新闻资讯</el-breadcrumb-item>
                            </el-breadcrumb>
                        </div>
                        <div class="xian"></div>
                    </div>
                    <div class="box_list" v-for="(item,index) in list" :key="index" @click="handelJump(item.id)">
                        <div class="img">
                            <img :src="item.imgUrl" alt="">
                        </div>
                        <div class="text_box">
                            <h5>{{item.title}}</h5>
                            <h6>{{item.summary}}</h6>
                            <router-link to="/news/newsdetail">查看详情</router-link>
                            <div class="data">
                                <span>
                                    <i class="iconfont">&#xe71c;</i>
                                    {{item.createTime}}
                                </span>
                                <span>
                                    <i class="iconfont">&#xe71e;</i>
                                    {{item.viewCount}}人看过
                                </span>
                            </div>
                        </div>
                    </div>
                    <el-pagination
                        v-if="total>10"
                        class="pag"
                        small
                        background
                        layout="prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import User from "../components/User.vue"
import { GetNewsList } from '../api/list'
    export default {
        components: {
            Header,
            Footer,
            User
        },
        data() {
            return {
                total:0,
                limit : '',
                page : '',
                list:''

            }
        },
        created () {
            GetNewsList(this.limit,this.page).then(res=>{
                
                this.list = res.data.data
            })
        },
        methods: {
            handelJump(id) {
                this.$router.push({
                    path:'/news/newsdetail',
                    name:'Newsdetail',
                    query:{
                        id:id
                    }
                })
            }
        },
    }
</script>

<style  scoped>
@import url(../assets/css/News.css);
</style>